// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EasyLoggerList {
  background: #f5a133;
  margin: 25px 50px 75px 10px;
}

tr td {
  transition: background 3s ease;
}
tr.active td {
  background: red;
  transition: background 3s ease;
}

/* https://stackoverflow.com/questions/29401711/newlines-character-ignored-by-jsx-table */
 
.nopre {
  background-color: lightblue;
}

.withpre {
  background-color: lightgreen;
  white-space: pre;
}

.withprewrap {
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/easyLogger/EasyLoggerList.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,8BAA8B;AAChC;;AAEA,yFAAyF;;AAEzF;EACE,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".EasyLoggerList {\n  background: #f5a133;\n  margin: 25px 50px 75px 10px;\n}\n\ntr td {\n  transition: background 3s ease;\n}\ntr.active td {\n  background: red;\n  transition: background 3s ease;\n}\n\n/* https://stackoverflow.com/questions/29401711/newlines-character-ignored-by-jsx-table */\n \n.nopre {\n  background-color: lightblue;\n}\n\n.withpre {\n  background-color: lightgreen;\n  white-space: pre;\n}\n\n.withprewrap {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
