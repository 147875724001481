import Resizer from 'react-image-file-resizer'
import { useState, useEffect, useCallback } from 'react'

export async function resizeImage(image) {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            image,
            800,
            800,
            'JPEG',
            100,
            0,
            (uri) => {
                resolve(uri)
            },
            'base64'
        )
    })
}

export function safeString(str) {
    var safeStr = str.replace('%20', ' ')
    //remove special characters
    //allow single dot
    safeStr = safeStr.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]+(\.(?!\.))/gi,
        ''
    )

    return safeStr
}

export function makeGalleryUrl(protocol, port, folder, id) {
    var currentDomain = window.location.host
    var updatedDomain = currentDomain.replace(/:\d+$/, `:${port}`)
    if (updatedDomain === currentDomain) {
        updatedDomain = `${updatedDomain}${port}`
    }
    const imageUrl = `${protocol}://${updatedDomain}/g/${folder}/${id}`

    return imageUrl
}

export const useDeviceOrientation = () => {
    const [heading, setHeading] = useState(0)
    const [permissionGranted, setPermissionGranted] = useState(false)

    useEffect(() => {
        // Only attach listener if we already have permission
        if (!permissionGranted) return

        const handleOrientation = (event) => {
            // iOS WebKit-specific property
            if (event.webkitCompassHeading) {
                setHeading(event.webkitCompassHeading)
            }
        }

        window.addEventListener('deviceorientation', handleOrientation, true)
        return () => {
            window.removeEventListener(
                'deviceorientation',
                handleOrientation,
                true
            )
        }
    }, [permissionGranted])

    const requestMotionPermission = useCallback(() => {
        // Must be called by a user gesture to avoid iOS Safari's NotAllowedError
        if (
            typeof DeviceMotionEvent !== 'undefined' &&
            typeof DeviceMotionEvent.requestPermission === 'function'
        ) {
            DeviceMotionEvent.requestPermission()
                .then((permissionState) => {
                    if (permissionState === 'granted') {
                        setPermissionGranted(true)
                    } else {
                        console.warn('Device motion permission denied')
                    }
                })
                .catch((err) => {
                    console.error(
                        'Error requesting device motion permission:',
                        err
                    )
                })
        } else {
            // For browsers that don’t require explicit permission
            setPermissionGranted(true)
        }
    }, [])

    return { heading, permissionGranted, requestMotionPermission }
}
