export function printLogs(array) {
    console.log('Printing array ' + array.length)
    for (var i = 0; i < array.length; i++) {
        const log = array[i]
        console.log('createdAt ' + log.createdAt + ', message ' + log.message)
    }
}

export function printIndexes(array) {
    console.log('Printing indexes ' + array.length)
    for (var i = 0; i < array.length; i++) {
        const index = array[i]
        console.log('index ' + index)
    }
}

export function calculateSegmentColor(index, total) {
    // Map the index to a hue in the HSL color space
    // Start from 0.67 (blue) and go backwards to 0.0 (red)
    const hueStart = 0.67
    const hueEnd = 0.0
    const hue = hueStart - (index / total) * (hueStart - hueEnd)
    const saturation = 1 // full color saturation
    const lightness = 0.5 // normal lightness

    // Convert the HSL color to RGB
    return hslToRgb(hue, saturation, lightness)
}

export function calculateDistance(coord1, coord2) {
    // Haversine formula to calculate distance between two coordinates in meters assuming a spherical Earth
    const R = 6371e3 // meters
    const lat1 = (coord1.lat * Math.PI) / 180
    const lat2 = (coord2.lat * Math.PI) / 180
    const deltaLat = ((coord2.lat - coord1.lat) * Math.PI) / 180
    const deltaLng = ((coord2.lng - coord1.lng) * Math.PI) / 180

    const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1) *
            Math.cos(lat2) *
            Math.sin(deltaLng / 2) *
            Math.sin(deltaLng / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c // in meters
}

function hslToRgb(h, s, l) {
    let r, g, b

    if (s === 0) {
        r = g = b = l // achromatic
    } else {
        const hue2rgb = (p, q, t) => {
            if (t < 0) t += 1
            if (t > 1) t -= 1
            if (t < 1 / 6) return p + (q - p) * 6 * t
            if (t < 1 / 2) return q
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
            return p
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
    }

    return `#${Math.round(r * 255)
        .toString(16)
        .padStart(2, '0')}${Math.round(g * 255)
        .toString(16)
        .padStart(2, '0')}${Math.round(b * 255)
        .toString(16)
        .padStart(2, '0')}`
}

export function extractLatLng(inputString) {
    const regex = /(-?\d+\.\d+),\s*(-?\d+\.\d+)/
    const match = inputString.match(regex)

    if (match) {
        const latitude = parseFloat(match[1])
        const longitude = parseFloat(match[2])
        return { lat: latitude, lng: longitude }
    } else {
        return { lat: null, lng: null }
    }
}
