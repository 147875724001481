// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .centered-div  {
 /* background-color: #bbde3e; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .font-loader {
    font-family: 'Chilanka';
  }`, "",{"version":3,"sources":["webpack://./src/pages/prostitutes/ProstitutesPage.css"],"names":[],"mappings":"CAAC;CACA,+BAA+B;IAC5B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":[" .centered-div  {\n /* background-color: #bbde3e; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n  }\n\n  .font-loader {\n    font-family: 'Chilanka';\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
