import React from 'react'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
// import moment from "moment";
import { format } from 'date-fns'
import './EasyLoggerList.css'
// import { printLogs, printIndexes } from "./LogsUtils.js";

function EasyLoggerList({ logs, isNoUser }) {
    // console.log(`logs ${logs.length}`);
    return render(logs, isNoUser)
    /* ***** */

    function render(logs, isNoUser) {
        const hasLogs = logs.length > 0
        if (hasLogs) {
            return listView(logs, isNoUser)
        } else {
            return noLogsView()
        }
    }

    //using pre tag to /n to work
    function listView(logs, isNoUser) {
        return (
            <Table striped bordered hover>
                <tbody>
                    {logs.map((log, index) => (
                        <tr key={index} className={rowClass(index)}>
                            <td style={{ width: '20%' }}>
                                {formatDate(log.createdat)}
                            </td>
                            {logTypeColumn(isNoUser, log)}
                            <td className="withprewrap"> {log.message}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    function rowClass(index) {
        return ''
        // const className = activeRows.includes(index) ? "active" : "";
        // console.log(" index " + index + " className " + className);
        // return className;
    }

    function formatDate(timeString) {
        // return "tmp date";
        // return moment(timeString).format("MMM DD, HH:mm:ss SSS");
        console.log(`formatDate ${timeString}`)
        return format(new Date(timeString), 'MMM dd, HH:mm:ss SSS')
    }

    function logTypeColumn(isNoUser, log) {
        if (isNoUser) {
            return <td> {log.logtype}</td>
        }
        return
    }

    function noLogsView() {
        return (
            <Alert variant="info">
                <h1>No logs :(</h1>
            </Alert>
        )
    }
}
export default EasyLoggerList
