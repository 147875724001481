import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, ImageListItem } from '@mui/material'
import { getGallerySet } from '../../api/api'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
const uiUtils = require('../../reactUtils/uiUtils')

function GallerySetPage() {
    const [imagesArr, setImagesArr] = useState([])
    const [layoutOption, setLayoutOption] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    let urlParams = useParams()
    const [folder, setFolder] = useState('')

    useEffect(() => {
        onPageLoad()
    }, [])

    async function onPageLoad() {
        var id = urlParams.id
        id = id.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\]/gi, '')
        id = id.toLocaleLowerCase()
        document.title = id
        const imagesTmp = await getGallerySet(id)
        // console.log(`images ${imagesTmp}`);

        setFolder(id)

        const imagesObjects = imagesTmp.map((name) => ({
            url: uiUtils.makeGalleryUrl('https', '', id, name),
            name: name,
        }))

        setImagesArr(imagesObjects)
        setIsLoading(false)
    }

    if (isLoading) {
        return <div style={{ padding: '8px' }}>...</div>
    } else {
        return (
            <>
                {segmentedControl(layoutOption)}
                {grid(imagesArr)}
            </>
        )
    }

    function grid(imagesArr) {
        const layoutSizeVal = layoutSize()
        const screenHeight = window.innerHeight

        return (
            <Grid container spacing={layoutOption === 0 ? 8 : 0.5}>
                {imagesArr.map((item) => (
                    <Grid item xs={layoutSizeVal} sm={layoutSizeVal} key={item}>
                        <ImageListItem>
                            <div
                                style={{
                                    maxHeight: screenHeight * 0.98,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={`${item.url}`}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: screenHeight * 0.98,
                                        objectFit: 'contain',
                                    }}
                                    alt={item}
                                    loading="lazy"
                                    onClick={() =>
                                        onImageTapped(item.name, folder)
                                    }
                                />
                            </div>
                        </ImageListItem>
                    </Grid>
                ))}
            </Grid>
        )
    }

    function layoutSize() {
        if (layoutOption === 0) {
            return 12
        } else if (layoutOption === 1) {
            return 6
        } else if (layoutOption === 2) {
            return 3
        }
        return 12
    }

    function segmentedControl(layoutOption) {
        return (
            <>
                <div style={{ padding: '4px' }}>
                    {
                        <ButtonGroup
                            style={{ flex: 1 }}
                            variant="outlined"
                            size="small"
                            aria-label="small outlined button group"
                        >
                            <Button
                                color={
                                    layoutOption === 0 ? 'secondary' : 'primary'
                                }
                                onClick={() => setLayoutOption(0)}
                            >
                                1
                            </Button>
                            <Button
                                color={
                                    layoutOption === 1 ? 'secondary' : 'primary'
                                }
                                onClick={() => setLayoutOption(1)}
                            >
                                2
                            </Button>
                            <Button
                                color={
                                    layoutOption === 2 ? 'secondary' : 'primary'
                                }
                                onClick={() => setLayoutOption(2)}
                            >
                                4
                            </Button>
                        </ButtonGroup>
                    }
                </div>
            </>
        )
    }
}

const onImageTapped = (name, folder) => {
    // Open a new page in a new tab
    var currentDomain = window.location.host
    const url = `http://${currentDomain}:4000/g/${folder}/${name}`
    window.open(url, '_blank')
}

export default GallerySetPage
