// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullscreen-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    max-height: 100vh;
    padding: 25px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/gallery/DetailImagePage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,aAAa;EACf","sourcesContent":[".fullscreen-image {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    object-position: center;\n    max-height: 100vh;\n    padding: 25px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
