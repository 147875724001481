import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { getGallerySet } from '../../api/api'
const uiUtils = require('../../reactUtils/uiUtils')

function RandomGalleryGamePage() {
    const [images, setImages] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [isEnded, setIsEnded] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        onPageLoad()
    }, [])

    async function onPageLoad() {
        const cleanId = id
            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\]/gi, '')
            .toLowerCase()
        document.title = cleanId
        const imageNames = await getGallerySet(cleanId)

        const shuffledImages = imageNames
            .map((name) => ({
                url: uiUtils.makeGalleryUrl('https', '', cleanId, name),
                name: name,
            }))
            .sort(() => Math.random() - 0.5)

        setImages(shuffledImages)
        setIsLoading(false)
    }

    const handleNext = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1)
        } else if (isEnded) {
            setCurrentIndex(0)
            setIsEnded(false)
            onPageLoad()
        } else {
            setIsEnded(true)
        }
    }

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1)
            setIsEnded(false)
        }
    }

    if (isLoading) {
        return <div style={{ padding: '8px' }}>Loading...</div>
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
            }}
        >
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Button
                    variant="contained"
                    onClick={handlePrev}
                    disabled={currentIndex === 0}
                >
                    Prev
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    style={{ marginLeft: '10px' }}
                >
                    Next
                </Button>
            </div>

            {isEnded ? (
                <Typography variant="h4">The end</Typography>
            ) : (
                <>
                    <img
                        src={images[currentIndex].url}
                        alt={images[currentIndex].name}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '70vh',
                            objectFit: 'contain',
                        }}
                    />
                    <Typography variant="body1" style={{ marginTop: '10px' }}>
                        Image {currentIndex + 1} of {images.length}
                    </Typography>
                </>
            )}
        </div>
    )
}

export default RandomGalleryGamePage
